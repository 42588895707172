export default {
    installments() {
        this.$store.commit('updateInstallments', this.installments)
    },
    travelers: {
        handler: function(travelers) {
            let result = true
            travelers.forEach(traveler => {
                if (
                    traveler.name.trim().split(/\s+/).length < 2 ||
                    (traveler.document.replace(/[^0-9]/g, '').length < 11 && !traveler.noCpf) ||
                    (traveler.rg.replace(/[^0-9]/g, '').length < 5 && !traveler.noRg)
                ) {
                    result = false
                }
            })

            this.$store.commit('updateTravelers', travelers)
            this.allTravelersFilled = result
        },
        deep: true
    },

    paymentMethod: {
        handler: function(value) {
            if (!value) {
                return
            }

            this.textButton = this.typesPayments.find((typePayment) => typePayment.code === value).textButton
        },
        deep: true,
    }
}
