import getApiClient from '@/plugins/api-client'
import {cpf} from 'cpf-cnpj-validator'
import {format} from "date-fns";
import Vue from "vue";

export default {
    openTerms(type = 'companyTerms') {
        this.showTerms = true
        this.openedTerms = this.terms[type]
        this.openedTermsTitle = (type === 'company' ? 'Vou de Trip' : this.$store.state.trip.agency.name)
    },
    hideTerms() {
        this.showTerms = false
        this.openedTerms = null
    },
    createOrder() {
        const self = this,
            api = getApiClient(self.$store)

        return new Promise((resolve, reject) => {
            self.$store.dispatch('updateCart', {travelers: self.$store.state.travelers, acceptedTerms: this.acceptedTerms})
                .then(() => {
                    api.post('orders', self.orderData)
                        .then(result => {
                            resolve(result.data)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })

        })
    },
    goToPayment() {
        this.$store.commit('setAcceptedTerms', this.terms)
        this.$store.commit('setPaymentMethod', this.paymentMethod)

        this.$store.dispatch('updateCart', {
            travelers: this.$store.state.travelers,
            paymentMethod: this.paymentMethod,
            paymentType: this.paymentMethod === 'credit_card' ? 1 : 3
        })
          .then(() => {
              this.$router.push({name: 'Payment'})
          })
          .catch((error) => {
              console.log(error)
          })
    },

    loadAvailableInstallments() {
        const self = this,
            api = getApiClient(self.$store)

        self.$store.commit('startLoading')

        api.get(`cart/calculate_installments?price=${self.totalValue}`)
            .then(result => {
                self.availableInstallments = []
                for (let installmentOption in result.data.installments) {
                    const installment = result.data.installments[installmentOption],
                        installmentAmount = new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(installment.installment_amount),
                        totalAmount = new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(installment.amount),
                        installmentObj = {
                            label: `${installment.installment}x de ${installmentAmount}`,
                            value: installment.installment,
                            description: `Total de ${totalAmount}`,
                            total: installment.amount
                        }

                    if (! self.installments.value) {
                        self.installments = installmentObj
                    }
                    self.availableInstallments.push(installmentObj)
                }
                self.$store.state.installments = self.availableInstallments
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                self.$store.commit('stopLoading')
            })
    },

    createTravelerData() {
        for (let i = 0; i < this.totalPeople; i++) {
            if (! this.travelers[i]) {
                this.$set(this.travelers, i, {name: '', document: '', rg: '', noCpf: false, noRg: false})
            }

            if (i === 0) {
                Vue.set(this.travelers, i, {
                    name: this.$store.state.session.user.name,
                    document: this.$store.state.session.user.cpf,
                    rg: this.$store.state.session.user.rg,
                    noCpf: false,
                    noRg: false
                })
            }
        }

        if (this.travelers.length > this.totalPeople) {
            const diff = this.travelers.length - this.totalPeople
            this.travelers = this.travelers.slice(0, -diff)
        }
    },

    loadTerms() {
        const self = this,
            api = getApiClient(self.$store)

        self.$store.commit('startLoading')
        api.get(`fetch_terms/${self.$store.state.trip.id}`)
            .then(result => {
                self.terms = result.data

                for (let term in self.terms) {
                    if (self.terms[term] && self.terms[term].id) {
                        self.totalTerms++
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                self.$store.commit('stopLoading')
            })
    },

    validateCpf(value) {
        if (value.length === 14) {
            return cpf.isValid(value) ? true : 'CPF inválido'
        }

        return false
    },

    validateName(value) {
        if (value.trim().split(/\s+/).length < 2) {
            return 'Preencha o nome completo'
        }

        return true
    },

    uniqueCpf(value) {
        let usingSameCpf = 0;
        this.travelers.forEach(traveler => {
            if (traveler.document === value) {
                usingSameCpf++
            }
        })

        return usingSameCpf > 1 ? 'Este CPF já foi adicionado' : true
    },

    uniqueRg(value) {
        let usingSameRg = 0;
        this.travelers.forEach(traveler => {
            if (traveler.rg === value) {
                usingSameRg++
            }
        })

        return usingSameRg > 1 ? 'Este RG já foi adicionado' : true
    },

    validateRg(value) {
        return value.length > 4;
    },

    findDuplicates: function(arr) {
        if (Array.isArray(arr)) {
            return arr.filter((item, index) => arr.indexOf(item) !== index).length
        }

        return false
    },

    hasInvalidCpf: function(arr) {
        let result = false
        if (Array.isArray(arr)) {
            arr.forEach(cpf => {
                if (cpf && typeof(this.validateCpf(cpf)) !== 'boolean') {
                    result = true
                }
            })

            return result
        }

        return result
    },

    hasInvalidRg: function(arr) {
        let result = false
        if (Array.isArray(arr)) {
            arr.forEach(rg => {
                if (rg && typeof(this.validateRg(rg)) !== 'boolean') {
                    result = true
                }
            })
        }

        return result
    },

    changeInstallments(typePaymentCode) {
        const cart = this.$store.state.cart,
          coupon = cart.coupon

        if (coupon && coupon.paymentMethod) {
            if (coupon.paymentMethod !== typePaymentCode) {
                return
            }
        }

        if (this.paymentMethod != typePaymentCode) {
            this.installments = {}
        }

        this.paymentMethod = typePaymentCode
    },

    getCartItems() {
        const result = []

        let boardingPlace = this.$store.state.trip.boardingPlaces.filter(place => {
            return place.id === this.$store.state.cart.boardingPlace
        })[0]

        if (boardingPlace) {
            const time = format(new Date(boardingPlace.date), 'dd/MM/yyyy HH:mm')
            boardingPlace = `Embarque: ${boardingPlace.place} - ${time}`
        }

        this.$store.state.cart.accommodations.forEach(accommodation => {
            result.push({
                item_id: this.$store.state.cart.id,
                item_name: accommodation.name,
                affilliation: this.$store.state.trip.agency.name,
                price: accommodation.value,
                quantity: accommodation.quantity,
                item_variant: boardingPlace
            })
        })

        this.$store.state.cart.optionals.forEach(optional => {
            result.push({
                item_id: this.$store.state.cart.id,
                item_name: optional.name,
                affilliation: this.$store.state.trip.agency.name,
                price: optional.value,
                quantity: optional.quantity
            })
        })

        return result
    },

    applyCoupon() {
        const self = this,
          api = getApiClient(self.$store),
          cartId = self.$store.state.cart.id,
          removal = !self.couponCode

        self.couponError = null

        if (cartId) {
            self.$store.commit('startLoading')
            self.applyingCoupon = true

            api.patch(`${cartId}/apply_coupon`, {
                couponCode: self.couponCode
            }, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            })
              .then(result => {
                  self.$store.state.cart.coupon = result.data.coupon

                  if (result.data.coupon?.paymentMethod) {
                      self.paymentMethod = result.data.coupon.paymentMethod
                  }
              })
              .catch(error => {
                  self.couponError = error?.response?.data?.detail || `Falha ao ${removal ? 'remover' : 'aplicar'} cupom`
              })
              .finally(() => {
                  self.loadAvailableInstallments()
                  self.$store.commit('stopLoading')
                  self.applyingCoupon = false
              })
        }
    },

    removeCoupon() {
        this.couponCode = null
        this.applyCoupon()
    }
}
