export default function() {
    if (window.dataLayer) {
        window.dataLayer.push({event: 'pageview', cart: this.$store.state.cart, page: 'payment'});
    }

    this.loadAvailableInstallments()
    this.createTravelerData()
    this.loadTerms()

    let paymentMethodDefault = this.typesPayments.find(
        (typePayment) => typePayment.default
    )

    this.paymentMethod = paymentMethodDefault.code
    this.textButton = paymentMethodDefault.textButton
    this.$store.state.errors = []
}
