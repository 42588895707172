export default function() {
    return {
        showTerms: false,
        nextStep: {
            name: 'Login'
        },
        nextStepPix: {
            name: 'Complete'
        },
        availableInstallments: [],
        installments: {},
        couponCode: null,
        applyingCoupon: false,
        couponError: null,
        typesPayments: [
            {
                code: 'credit_card',
                name: 'Cartão de crédito',
                descriptions: [
                    'Até 12x',
                    'Clique em "Inserir dados do cartão" para selecionar a quantidade de parcelas',
                ],
                default: true,
                textButton: 'Inserir dados do cartão',
            },
            {
                code: 'pix',
                name: 'Pix',
                descriptions: [
                    'À vista',
                ],
                default: false,
                textButton: 'Realizar pix',
            }
        ],
        paymentMethod: null,
        textButton: null,
        travelers: [],
        allTravelersFilled: false,
        acceptedTerms: [],
        totalTerms: 0,
        openedTermsTitle: null,
        openedTerms: null,
        terms: {
            agencyTerms: null,
            companyTerms: null
        }
    }
}
