import computed from './attrs/computed'
import data from './attrs/data'
import methods from './attrs/methods'
import mounted from './attrs/mounted'
import watch from './attrs/watch'

import CartOverview from '../../components/CartOverview/Index'

import {mask} from 'vue-the-mask'

export default {
    name: 'Checkout',
    computed,
    data,
    methods,
    mounted,
    watch,
    components: {
        CartOverview
    },
    directives: {
        mask
    }
}
